export default {
  init() {
    // JavaScript to be fired on all pages
        
    // Tabs
    $('.tabs-dropdown .dropdown-item').on('click',  function(){
        var btnObj = $(this).parent().siblings('button');
        $(btnObj).text($(this).text());
        $(btnObj).val($(this).text());
    });
        
    $('.tabs-dropdown .dropdown-item').click(function (e) {
        $(this).removeClass('active');
        e.preventDefault();
        $(this).tab('show');
    });

    // Navbar resize/show/hide
    $(function () {
        let prevScrollpos = $(window).scrollTop();
        let hideNavBarOffsetHeight = $(window).height() * 0.3;
        let styleNavBarOffsetHeight = $(window).height() * 0;

        $(window).scroll(function () {

            // When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar
            let currentScrollPos = window.pageYOffset;

            if ($(window).scrollTop() > hideNavBarOffsetHeight) {
                if (prevScrollpos > currentScrollPos) {
                    $('#nav-site').removeClass('nav-hide').addClass('position-fixed');
                    $('.wrap').addClass('wrap-scrolled');
                } else {
                    $('#nav-site').addClass('nav-hide');
                }
            }

            prevScrollpos = currentScrollPos;

            // When the user scrolls down 80px from the top of the document, alter navbar's styling
            if ($(window).scrollTop() > styleNavBarOffsetHeight) {
                $('#nav-site').addClass('shadow').addClass('nav-scrolled');
                $('body').removeClass('modal-open');
            } else {
                $('#nav-site').removeClass('shadow').removeClass('nav-scrolled').removeClass('position-fixed');
                $('.wrap').removeClass('wrap-scrolled');
                $('body').removeClass('modal-open');
            }
        });
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    
  },
};
